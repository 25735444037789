<template>
	<div>
		<ContentHeader title="Perawatan" />
		 <div class="content-header">
			<div class="container-fluid">
	            <div class="card">
	              <div class="card-header">
	                <h3 class="card-title"><div>Kunjungan / Antrian Pasien</div></h3>

	                <div class="card-tools">
	                  <button type="button" class="btn btn-tool" data-card-widget="collapse" title="Collapse">
	                    <i class="fas fa-minus"></i>
	                  </button>
	                </div>
	              </div>
	              <div class="card-body" style="display: block;">
	              	<table class="table table-bordered table-hover">
	              		<thead type="primary">
	        				    <tr>
	        				      <th scope="col" class="text-center" style="width: 80px;">No</th>
	        				      <th scope="col" class="text-center" style="width: 150px;">Nomor Antrian</th>
	        				      <th scope="col" class="text-center" style="min-width: 250px;">NAMA</th>
	        				      <th scope="col" class="text-center" style="min-width: 150px;">DOKTER</th>
	        				      <th scope="col" style="min-width: 110px;">POLI</th>
	        				      <th scope="col" style="min-width: 110px;">LAYANAN</th>
	        				      <th scope="col" class="text-center" style="width: 150px;">STATUS</th>
	        				      <th scope="col" class="text-center" style="width: 170px;">WAKTU KUNJUNGAN</th>
	        				      <th scope="col" class="text-center" style="width: 100px;">ACTION</th>
	        				    </tr>
	        				  </thead>
	        				  <tbody>
	        				  	<tr v-for="(row, index) in kunjungan" :key="row.id">
	        				  		<td scope="row" class="text-center" style="width: 80px;">{{ index + 1 }}</td>
	        				  		<td class="text-center" style="width: 150px;">{{ row.nomor }}</td>
	        				  		<td class="text-sm"><b>{{ row.pasien?.nomor_rekam_medis }}</b> - {{ row.pasien?.nama }}</td>
									<td class="text-sm">{{ row.dokter.nama_lengkap}}</td>
	        				  		<td style="min-width: 110px;">{{ row.poli?.nama }}</td>
	        				  		<td style="min-width: 110px;">{{ row.layanan?.nama }}</td>
	        				  		<td class="text-center" style="width: 150px;">{{ row.status }}</td>
	        				  		<td class="text-center" style="width: 170px;">{{ row.waktu_kunjungan }}</td>
	        				  		<td>
										<div class="btn-group" >
											<button type="button" class="btn btn-sm btn-info dropdown-toggle dropdown-icon" data-toggle="dropdown" aria-expanded="false">Proses  <span class="sr-only"></span> </button>
											<div class="dropdown-menu" role="menu" style="width: 150px;">
												<router-link :to="`/tambah-data-perawatan/${row.id}`" class="ml-1 mb-1">
													<el-button  type="primary">Pemeriksaan Awal</el-button>
												</router-link>
												
												<el-popconfirm title="Hapus Data?" @confirm="hapusData(row.id)" class="ml-1 mt-1">
													<template #reference>
														<el-button type="danger" :loading="loading">Batalkan</el-button>
													</template>
												</el-popconfirm>
											</div>
										</div>
							      </td>
	        				  	</tr>
	        				  </tbody>
	              	</table>
	              	<p class="text-center mt-2">{{cekDataKunjungan}}</p>
	              	<p class="text-center mt-2" v-if="memuat_kunjungan">Memuat Data...</p>
	              </div>
	              <!-- /.card-body -->
	              <div class="card-footer" style="display: block;">
	                
	              </div>
	              <!-- /.card-footer-->
	            </div>


	            <div class="card">
	              <div class="card-header">
	                <h3 class="card-title"><div>Pemeriksaan</div></h3>

	                <div class="card-tools">
	                  <button type="button" class="btn btn-tool" data-card-widget="collapse" title="Collapse">
	                    <i class="fas fa-minus"></i>
	                  </button>
	                </div>
	              </div>
	              <div class="card-body" style="display: block;">
	              	<table class="table table-bordered table-hover">
	              		<thead>
	    				     <tr>
								<th scope="col" class="text-center" style="width: 80px;">No</th>
								<th scope="col" class="text-center" style="width: 150px;">Nomor Antrian</th>
								<th scope="col" class="text-center" style="min-width: 250px;">NAMA</th>
								<th scope="col" class="text-center" style="min-width: 150px;">DOKTER</th>
								<th scope="col" style="min-width: 110px;">POLI</th>
								<th scope="col" style="min-width: 110px;">LAYANAN</th>
								<th scope="col" class="text-center" style="width: 150px;">STATUS</th>
								<th scope="col" class="text-center" style="width: 170px;">WAKTU KUNJUNGAN</th>
								<th scope="col" class="text-center" style="width: 100px;">ACTION</th>
							</tr>
	    				  </thead>
	    				  <tbody>
	    				  	<tr v-for="(row, index) in pasienTelahDiperiksa" :key="row.id">
	    				  		<td scope="row" class="text-center" style="width: 80px;">{{ index + 1 }}</td>
								<td class="text-center" style="width: 150px;">{{ row.nomor }}</td>
								<td class="text-sm"><b>{{ row.pasien?.nomor_rekam_medis }}</b> - {{ row.pasien?.nama }}</td>
								<td class="text-sm">{{ row.dokter.nama_lengkap}}</td>
								<td style="min-width: 110px;">{{ row.poli?.nama }}</td>
								<td style="min-width: 110px;">{{ row.layanan?.nama }}</td>
								<td class="text-center" style="width: 150px;">{{ row.status }} </td>
								<td class="text-center" style="width: 170px;">{{ row.waktu_kunjungan }}</td>
	    				  		<td>
									<div class="btn-group"  v-if="row.status != 'dalam antrian' && row.status != 'selesai'">
					                    <button type="button" class="btn btn-sm btn-info dropdown-toggle dropdown-icon"  data-toggle="dropdown" aria-expanded="false">Proses  <span class="sr-only"></span> </button>
									<div class="dropdown-menu" role="menu" style="width: 170px;" >
										<router-link :to="`/edit-data-perawatan/${row.id}`" class="ml-1">
											<el-button type="primary">Pemeriksaan Utama</el-button>
										</router-link>
										<router-link :to="`/rekam-medis-detail/${row.pasien.id}`" >
													<el-button  type="success" class="ml-1 mt-1 ">Riwayat Pemeriksaan</el-button>
										</router-link>
										<el-popconfirm title="Hapus Data?" @confirm="hapusData(row.id)" class="ml-1 mt-1">
											<template #reference>
												<el-button type="danger" :loading="loading">Batalkan</el-button>
											</template>
										</el-popconfirm>
									</div>
									</div>
								</td>
	    				  	</tr>
	    				  </tbody>
	              	</table>
	              	<p class="text-center mt-2">{{cekDataPasienTelahDiperiksa}}</p>
	              	<p class="text-center mt-2" v-if="memuat_pemeriksaan">Memuat Data...</p>
	              </div>
	              <!-- /.card-body -->
	              <div class="card-footer" style="display: block;">
	                
	              </div>
	              <!-- /.card-footer-->
	            </div>

			</div>
		</div>
	</div>
</template>

<script setup>
	import ContentHeader from '@/components/ContentHeader'

	import { ref, computed, onMounted } from 'vue'
	import axios from 'axios'
	import store from '@/store'
	import { ElMessage } from 'element-plus'
	
	
	const kunjungan = ref()
	const pasienTelahDiperiksa = ref()
	const cekDataKunjungan = ref()
	const cekDataPasienTelahDiperiksa = ref()
	const user 	 = computed(() => store.getters['auth/user'])
	const cabang = computed(() => store.getters['auth/cabang'])
	const memuat_kunjungan = ref(true)
	const memuat_pemeriksaan = ref(true)

	const getKunjungan = async () => {
		let response = await axios.get('api/kunjungan/getWhereDalamAntrian/' + user.value.cabang_id)
		
		if (response.data == 'kosong') {
			cekDataKunjungan.value = 'Data Masih Kosong'
			kunjungan.value = null
			memuat_kunjungan.value = false
		} else {
			cekDataKunjungan.value = ''
			kunjungan.value = response.data
			memuat_kunjungan.value = false
		}	
	}

	const getPasienTelahDiperiksa = async () => {
		let response = await axios.get('api/kunjungan/getByDay/' + user.value.cabang_id)
		
		if (response.data == 'kosong') {
			cekDataPasienTelahDiperiksa.value = 'Data Masih Kosong'
			pasienTelahDiperiksa.value = null
			memuat_pemeriksaan.value = false
		} else {
			cekDataPasienTelahDiperiksa.value = ''
			pasienTelahDiperiksa.value = response.data
			memuat_pemeriksaan.value = false
		}	
	}

	const loading = ref(false)
	const hapusData = async (id) => {
		loading.value = true
		axios.delete(`api/kunjungan/destroy/${id}/${cabang.value.tipe}`)
		.then(() => {
			ElMessage({
				showClose: true,
				message: 'Berhasil Menghapus Data.',
				type: 'success',
			})
			loading.value = false
			getKunjungan()
			getPasienTelahDiperiksa()
		})
		.catch((error) => {
			ElMessage({
				showClose: true,
				message: 'Gagal Menghapus Data.',
				type: 'error',
			})
			loading.value = false
			console.log(error) 
		})
	}
		
	onMounted(() => {
		getKunjungan()
		getPasienTelahDiperiksa()
	})
</script>